// src/components/Counter.js
import React, { useState } from 'react';

const Counter = () => {
  const [count, setCount] = useState(878);

  const handleYesClick = () =>
    setCount((prevCount) => prevCount + 1);

  return (
    <div>
      <h3>Souhaitez-vous avoir Yéko ?</h3>
      <button
        onClick={handleYesClick}
        style={{
          margin: '5px',
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#FFA500',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Oui
      </button>
      <button
        style={{
          margin: '5px',
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#e0e0e0',
          color: '#333',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Non
      </button>
      <p>Nombre de réponses positives : {count}</p>
    </div>
  );
};

export default Counter;
