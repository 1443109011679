// src/App.js
import React from 'react';
import Features from './components/Features';
import Counter from './components/Counter';
import Banner from './components/Banner';
import Hero from './components/Hero';
import Testimonials from './components/Testimonials';

const padding = '20px';

function App() {
  const isMobile = window.innerWidth <= 768;

  return (
    <div
      style={{
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: '#1e539a',
        color: '#fff',
        padding: padding,
      }}
    >
      <Banner />
      <Hero />
      <div
        style={{
          marginTop: '20px',
          padding: padding,
        }}
      >
        <Testimonials />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          padding: padding,
        }}
      >
        <div
          style={{
            flex: '1 1 auto',
            margin: '10px',
          }}
        >
          <Features />
        </div>
        <div
          style={{
            flex: '1 1 auto',
            margin: '10px',
          }}
        >
          <Counter />
        </div>
      </div>
    </div>
  );
}

export default App;
