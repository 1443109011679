import React, { useState, useEffect } from 'react';

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(() => {
    const eventDate = new Date(
      '2025-03-22'
    ).getTime();
    return eventDate - Date.now();
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1000);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (ms) => {
    const days = Math.floor(
      ms / (1000 * 60 * 60 * 24)
    );
    const hours = Math.floor(
      (ms / (1000 * 60 * 60)) % 24
    );
    const minutes = Math.floor(
      (ms / (1000 * 60)) % 60
    );
    const seconds = Math.floor((ms / 1000) % 60);
    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } =
    formatTime(timeLeft);

  return (
    <div>
      <h2 style={{ color: '#fff' }}>
        Lancement dans :
      </h2>
      <div
        style={{
          display: 'flex',
          gap: '20px',
        }}
      >
        <div
          style={{
            backgroundColor: '#333333',
            borderRadius: '10px',
            padding: '20px',
            width: '80px',
          }}
        >
          <h3
            style={{ margin: '0', color: '#FFFFFF' }}
          >
            {days}
          </h3>
          <p
            style={{ margin: '0', color: '#FFFFFF' }}
          >
            Jours
          </p>
        </div>
        <div
          style={{
            backgroundColor: '#333333',
            borderRadius: '10px',
            padding: '20px',
            width: '80px',
          }}
        >
          <h3
            style={{ margin: '0', color: '#FFFFFF' }}
          >
            {hours}
          </h3>
          <p
            style={{ margin: '0', color: '#FFFFFF' }}
          >
            Heures
          </p>
        </div>
        <div
          style={{
            backgroundColor: '#333333',
            borderRadius: '10px',
            padding: '20px',
            width: '80px',
          }}
        >
          <h3
            style={{ margin: '0', color: '#FFFFFF' }}
          >
            {minutes}
          </h3>
          <p
            style={{ margin: '0', color: '#FFFFFF' }}
          >
            Minutes
          </p>
        </div>
        <div
          style={{
            backgroundColor: '#333333',
            borderRadius: '10px',
            padding: '20px',
            width: '80px',
          }}
        >
          <h3
            style={{ margin: '0', color: '#FFFFFF' }}
          >
            {seconds}
          </h3>
          <p
            style={{ margin: '0', color: '#FFFFFF' }}
          >
            Secondes
          </p>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
