import React from 'react';
import logo from '../assets/logo.png'; // Corrected path to the logo

const Banner = () => (
  <div
    style={{
      position: 'relative', // Position relative for the container
      backgroundColor: '#ff9a23', // Transparent background to see the background
      padding: '20px 0',
      textAlign: 'center',
    }}
  >
    {/* White circle behind the logo */}
    <div
      style={{
        display: 'flex', // Using flexbox to center the circle and the logo
        justifyContent: 'center', // Horizontally centered
        alignItems: 'center', // Vertically centered
        marginBottom: '10px', // Space at the bottom
      }}
    >
      <div
        style={{
          width: '150px', // Width of the circle
          height: '150px', // Height of the circle
          borderRadius: '75px', // Rounded to make a circle
          backgroundColor: 'white', // White color
          display: 'flex', // Using flexbox to center the logo
          alignItems: 'center', // Vertically centered
          justifyContent: 'center', // Horizontally centered
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            width: '100px', // Adjust the size of the logo
            height: '100px', // Adjust the size of the logo
          }}
        />
      </div>
    </div>
  </div>
);

export default Banner;
