// src/components/Features.js
import React from 'react';

const features = [
  'Suivi des ponctualités',
  'Suivi des participations',
  'Suivi des exercices',
  'Suivi des notes',
  'Chat avec le prof principal',
];

const Features = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: '130px',
      borderRadius: '8px',
      width: '50%',
    }}
  >
    <h3
      style={{
        color: '#333',
        marginBottom: '10px',
        background: '#ff9a23',
        padding: '15px',
        borderRadius: '20px',
        fontSize: '1.5rem',
      }}
    >
      Nos fonctionnalités clés
    </h3>
    <ul
      style={{ listStyleType: 'none', padding: '0' }}
    >
      {features.map((feature, index) => (
        <li
          key={index}
          style={{
            color: '#fff',
            fontSize: '1rem',
            padding: '10px 0',
            borderBottom: '1px solid #eaeaea',
          }}
        >
          {feature}
        </li>
      ))}
    </ul>
  </div>
);

export default Features;
