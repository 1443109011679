import React from 'react';
import girlImage from '../assets/girl.png';
import Countdown from './Countdown';

const Hero = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      backgroundColor: '#1e539a',
      color: '#fff',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          textAlign: 'left',
          flex: '1 1 100%',
          marginLeft: '200px',
          maxWidth: '600px',
        }}
      >
        <h1
          style={{ fontSize: '2rem', margin: '0' }}
        >
          Bientôt la{' '}
          <span style={{ color: '#FFA500' }}>
            révolution du système éducatif africain
          </span>
        </h1>
        <div
          style={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            padding: '10px 20px',
            marginTop: '10px',
            display: 'inline-block',
          }}
        >
          <h2
            style={{
              fontSize: '1.5rem',
              margin: '0',
              color: '#1e539a',
            }}
          >
            Lancement 22 Mars 2025
          </h2>
        </div>
        <Countdown />
      </div>
      <img
        src={girlImage}
        alt="Fille souriante"
        style={{
          width: '100%',
          maxWidth: '600px',
          height: 'auto',
          borderRadius: '10px',
          marginTop: '20px',
          flex: '1 1 100%',
        }}
      />
    </div>
  </div>
);

export default Hero;
