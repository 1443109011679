// src/components/Testimonials.js
import React from 'react';
import Slider from 'react-slick';
import desireTropImage from '../assets/desire_trop.png';
import sandrineBleu from '../assets/sandrine_bleu.png';
import sostheneFondoImage from '../assets/sosthene_fondo.png';
import dorcasZokouImage from '../assets/dorcas_zokou.png';
import biniNazaire from '../assets/bini_nazaire.png';
import eugeneKouadio from '../assets/eugene_kouadio.png';
import 'slick-carousel/slick/slick.css'; // Import Slick CSS
import 'slick-carousel/slick/slick-theme.css'; // Import Slick Theme CSS
import './Testimonials.css'; // Import custom CSS for additional styling

const testimonials = [
  {
    name: 'Desiré Dro',
    role: 'Propriétaire d’agence de voyage',
    text: 'Je souhaite connaitre l’emploi du temps de ma fille et pouvoir tous suivre pendant que je suis au boulot.',
    image: desireTropImage,
  },
  {
    name: 'Sandrine Bleu',
    role: 'Entrepreneur',
    text: 'Mon petit-fils, il vit chez moi et sa maman vit en France quand elle me pose question sur son école, je ne sais pas quoi répondre.',
    image: sandrineBleu,
  },
  {
    name: 'Sosthene Fondo',
    role: 'Entrepreneur',
    text: 'Mon fils me cache ses notes et il est déjà trop tard pour agir. Vivement une solution efficace.',
    image: sostheneFondoImage,
  },
  {
    name: 'Bini Nazaire',
    role: 'Directeur de création',
    text: "Avec mes 3 enfants, c'est un gros casse-tête chinois de suivre tous les enfants dans leurs différentes écoles",
    image: biniNazaire,
  },
  {
    name: 'Eugène Kouadio',
    role: 'Fondateur d’école',
    text: 'Nous fondateurs, nous avons besoin de digitaliser de nombreuses taches de nos process quotidien.',
    image: eugeneKouadio,
  },
  {
    name: 'Dorcas Zokou',
    role: 'Entrepreneur',
    text: 'Lorsque ma fille part à l’école, je veux avoir le cœur en paix. Il parait qu’il y a de jeunes filles à qui on fait des propositions aux abords des écoles.',
    image: dorcasZokouImage,
  },
];

const Testimonials = () => {
  const settings = {
    dots: true, // Show pagination dots
    infinite: true, // Enable infinite looping
    speed: 500, // Transition speed in ms
    slidesToShow: 3, // Number of testimonials to show at once
    slidesToScroll: 1, // Number of testimonials to scroll on each action
    autoplay: true, // Enable auto-scroll
    autoplaySpeed: 3000, // Auto-scroll interval in ms (3 seconds)
    arrows: true, // Show navigation arrows
    responsive: [
      {
        breakpoint: 1024, // Screens smaller than 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600, // Screens smaller than 600px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="testimonials-container">
      <h3
        className="testimonials-title"
        style={{
          color: '#333',
          marginBottom: '10px',
          background: '#ff9a23',
          padding: '15px',
          borderRadius: '20px',
          fontSize: '1.5rem',
        }}
      >
        Attente du marché
      </h3>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div
            className="testimonial-card"
            key={index}
          >
            <img
              src={testimonial.image}
              alt={testimonial.name}
              className="testimonial-image"
            />
            <p className="testimonial-name-role">
              {testimonial.name} <br />
              <span className="testimonial-role">
                {testimonial.role}
              </span>
            </p>
            <p className="testimonial-text">
              {testimonial.text}
            </p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
